<template>
    <base-section id="404">
        <base-heading title="404" />

        <base-subheading class="primary--text" title="Oh!" />

        <base-subheading title="Looks like this page doesn't exist" />

        <base-img
            :src="require('@/assets/logo-white.jpeg')"
            class="mx-auto mb-5"
            contain
            height="150"
            width="350"
        />

        <div class="text-center">
            <base-btn :to="{ name: 'Home' }">Get me Out of Here</base-btn>
        </div>
    </base-section>
</template>

<script>
export default {
    name: 'FourOhFour',

    provide: {
        heading: { align: 'center' },
    },
};
</script>
